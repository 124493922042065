import {
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
} from 'vue-router';

const checkAuth = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  router: Router,
): void => {
  const { token, role } = JSON.parse(localStorage.getItem('user') ?? '{}');

  if (to.path.includes('login') && !token) {
    next();
    return;
  }

  if (token && !to.path.includes('login')) {
    checkRouteAccess(to, next, router, role);
  } else if (token) {
    router.push({ name: from.name });
  } else {
    router.push({ name: 'LoginView' });
  }
};

const checkRouteAccess = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  router: Router,
  role?: string,
): void => {
  if (to.path === '/user') {
    if (role !== 'Superadmin') router.push({ name: 'ExpenseView' });
    else next();
  } else {
    next();
  }
};

export default checkAuth;
