import { checkAuth } from '@/utils';
import { Component } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    component: (): Promise<Component> =>
      import('@/layout/ExpenseAppLayout.vue'),
    redirect: '/expense',
    children: [
      {
        path: 'expense',
        name: 'ExpenseView',
        component: (): Promise<Component> =>
          import('@/views/ExpenseAppView.vue'),
      },
      {
        path: 'statistic',
        name: 'StatisticView',
        component: (): Promise<Component> =>
          import('@/views/ExpenseAppView.vue'),
      },
      {
        path: 'user',
        name: 'UserView',
        component: (): Promise<Component> =>
          import('@/views/ExpenseAppView.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'LoginView',
    component: (): Promise<Component> => import('@/layout/LoginLayout.vue'),
  },
];

const router = createRouter({
  history: createWebHistory((import.meta.env || process.env).BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  checkAuth(from, to, next, router);
});

export default router;
